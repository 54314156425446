@use '@queries' as *;

.items-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @include min-1920-break {
    gap: 18px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .items {
    display: grid;
    //grid-template-columns: repeat( auto-fill, 188px );
    grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
    justify-content: flex-start;
    gap: 4px;
    height: 100%;

    @include min-1440-break {
      //grid-template-columns: repeat( auto-fit, 252px );
      grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
      gap: 12px;
    }
  }
}
